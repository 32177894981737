<template>
    <div class="option-switch row">
        <div class="label__container column justify-around q-pr-md">
            <span
                class="switch__label switch-off"
                :class="{'is-active': !darkMode}"
                @click="toggleDarkMode(false)"
            >
                Light Mode
            </span>
            
            <span
                class="switch__label switch-on"
                :class="{'is-active': darkMode}"
                @click="toggleDarkMode(true)"
            >
                Dark Mode
            </span>
        </div>

        <div class="switch__container">
            <label class="switch dark-mode-switch">
                <input 
                    class="dark-mode-checkbox" 
                    type="checkbox" 
                    :checked="darkMode"
                    @change="toggleDarkMode()">
                <span class="slide-pin"></span>
            </label>
        </div>
    </div>
</template>

<script setup>
    import { storeToRefs } from "pinia";
    import { useGeneralStore } from "@/stores/general";

    const store = useGeneralStore();
    const { darkMode } = storeToRefs(store);

    const toggleDarkMode = (value) => {
        if (value) {
            store.saveDarkMode(value)
        } else {
            store.saveDarkMode(!darkMode.value)
        }

        store.changeBlobSrc('dark')
    }
</script>